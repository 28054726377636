// extracted by mini-css-extract-plugin
export var caseContactFormSection = "by_k3";
export var caseStudyBackground__lineColor = "by_k0";
export var caseStudyHead__imageWrapper = "by_kX";
export var caseStudyProjectsSection = "by_k4";
export var caseStudyQuote__bgLight = "by_kY";
export var caseStudyQuote__bgRing = "by_kZ";
export var caseStudySolution__ring = "by_k8";
export var caseStudyVideo__ring = "by_k2";
export var caseStudy__bgDark = "by_kV";
export var caseStudy__bgLight = "by_kT";